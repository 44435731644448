import { graphql } from "gatsby"
import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { SiteMetadata, Carousel, ProductDisclaimer } from "../components/Shared"
import { ImageUploader } from "../components/Global/Gui/Image"
import { MoreInfoModal } from "../components/Global/Gui/Modals"
import {
  VinylColors,
  Spotify,
  SongSearchModal,
  SpotifySearchButton,
  ImageEditor,
} from "../components/ProductSpecific/GlassSongPlaque"
import {
  AddToCartButton,
  SoldOutButton,
} from "../components/Global/Gui/Buttons"
import { TextInput, Checkbox } from "../components/Global/Gui/Form"
import { CSSTransition } from "react-transition-group"
import { useAlert } from "react-alert"
import { Transition } from "../styles"

import { FaInfoCircle, FaCameraRetro } from "react-icons/fa"
import { getCartState, getSongPlaqueState } from "../redux/accessors"

const DesignSongPlaque = props => {
  const dispatch = useDispatch()
  const plaqueState = useSelector(getSongPlaqueState)
  const { plaque, stand, standExamples } = props.data
  const {
    artist,
    buttonColor,
    croppedPhotoURI,
    modal,
    photoURI,
    song,
    spotifySearchModal,
    tempPhotoURI,
  } = plaqueState
  const { posting, posted, error, message } = useSelector(getCartState)
  const alert = useAlert()

  const plaqueVariantID = process.env.glassPlaqueVariantID
  const standVariantID = process.env.glassPlaqueStandVariantID

  const [standPrice, updateStandPrice] = useState(null)

  useEffect(() => {
    if (stand) {
      const { price } = stand.variants.find(val => {
        if (val["price"]) {
          return true
        }
        return false
      })
      updateStandPrice(price)
    }
  }, [stand])

  const [colorClass, setColorClass] = useState(`text-vinyl-white`)
  const [htmlButtonColor, setHTMLButtonColor] = useState("#ffffff")
  const [playerBackground, setPlayerBackground] = useState({})
  const [standInfo, setOpenStandInfo] = useState(false)
  const [addStand, updateStandCheck] = useState(false)

  /*
    Safely removes the photo then unmounts the modal. 
    prevents an update on an unmounted component
  */
  useEffect(() => {
    if (tempPhotoURI === null && modal) {
      dispatch({
        type: "CLOSE_MODAL",
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempPhotoURI])

  useEffect(() => {
    let colorMap = {
      white: "#ffffff",
      yellow: "#fcbd08",
      pink: "#ff8ccd",
      black: "#000000",
      "light-blue": "#88ddfb",
      "mint-green": "#77d0bc",
    }
    setHTMLButtonColor(colorMap[buttonColor])
    setColorClass(`text-vinyl-${buttonColor}`)
  }, [buttonColor])

  useEffect(() => {
    if (photoURI) {
      setPlayerBackground({
        backgroundImage: "url(" + photoURI + ")",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        maxWidth: "100%",
      })
    } else {
      setPlayerBackground({})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photoURI])

  useEffect(() => {
    if (posting === true && error === null) {
      alert.info(message)
    }
    if (posting === false && error === null && posted === true) {
      alert.success(message)
    }
    if (posting === false && error === true) {
      alert.error(message)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [posting])

  return (
    <div>
      <SiteMetadata
        title="Start Designing!"
        description="Design your song glass plaque!"
      />
      <div className="bg-gray-100">
        <div className="container py-12 lg:pb-16">
          <div className="flex flex-wrap">
            <div
              className={`flex flex-col items-center justify-center 
               w-full md:w-1/2 xl:w-2/5 md:pl-12 pb-4`}
            >
              {modal && <ImageEditor />}
              {spotifySearchModal && <SongSearchModal />}

              {!photoURI && (
                <div className="flex flex-col justify-evenly space-y-4 items-stretch">
                  <div
                    className={`w-full rounded-md overflow-hidden group 
                      ${croppedPhotoURI !== null ? "shadow-sm" : ""}
                    `}
                  >
                    <ImageUploader
                      setPhotoURI={photoURI =>
                        dispatch({ type: "SELECT_PHOTO", photoURI })
                      }
                    >
                      <div
                        className={`
                          w-full 
                          outline-none 
                          inline-flex items-center 
                          px-4 py-2 
                          border border-transparent rounded-md shadow-sm 
                          text-sm font-medium 
                          ${Transition.DEFAULT}
                          text-white bg-annmade-1.0-navy hover:opacity-75 
                          focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-annmade-1.0-navy`}
                      >
                        <FaCameraRetro className="-ml-1 mr-2 h-5 w-5" />
                        Upload a Photo
                      </div>
                    </ImageUploader>
                  </div>

                  <SpotifySearchButton />
                </div>
              )}
              <CSSTransition
                in={croppedPhotoURI !== null && !modal}
                timeout={800}
                classNames="my-node"
                unmountOnExit
              >
                <div
                  className={
                    `rounded-md overflow-hidden group ` +
                    (croppedPhotoURI !== null ? "shadow-sm" : "")
                  }
                  style={playerBackground}
                >
                  <div
                    className={`
                      flex flex-col items-center justify-center 
                      song-plaque-container frosted-glass 
                      ${croppedPhotoURI !== null ? "sm:p-5 p-4" : ""}
                    `}
                  >
                    <Spotify color={htmlButtonColor} colorClass={colorClass} />
                  </div>
                </div>
              </CSSTransition>
            </div>
            <div className="w-full md:w-1/2 xl:w-3/5 pb-8 md:pl-12">
              {/* Song Title and Artist */}
              <div className="flex flex-col items-end">
                {song !== "" ? (
                  <div
                    className={`text-xs text-gray-700 mb-2 ${Transition.DEFAULT} opacity-100`}
                  >
                    {32 - song.length} characters remaining
                  </div>
                ) : (
                  <div
                    className={`text-xs text-gray-700 mb-2 ${Transition.DEFAULT} opacity-0`}
                  >
                    &nbsp;
                  </div>
                )}
                <TextInput
                  placeholder={"Song Title"}
                  value={song}
                  required={true}
                  onChange={e =>
                    dispatch({ type: "UPDATE_SONG", song: e.target.value })
                  }
                />
                {artist !== "" ? (
                  <div
                    className={`text-xs text-gray-700 mt-4 mb-2 ${Transition.DEFAULT} opacity-100`}
                  >
                    {32 - artist.length} characters remaining
                  </div>
                ) : (
                  <div
                    className={`text-xs text-gray-700 mt-4 mb-2 ${Transition.DEFAULT} opacity-0`}
                  >
                    &nbsp;
                  </div>
                )}

                <TextInput
                  placeholder={"Artist"}
                  value={artist}
                  required={true}
                  onChange={e =>
                    dispatch({ type: "UPDATE_ARTIST", artist: e.target.value })
                  }
                  classes={`mb-4`}
                />
              </div>

              <VinylColors />

              {/* Additional Options */}
              {stand && stand.availableForSale && standPrice ? (
                <div
                  className={`flex flex-row justify-between items-center my-6`}
                >
                  <div className="flex flex-row items-center justify-center">
                    <label className="inline-flex items-center">
                      <Checkbox
                        onClick={e => {
                          e.target.value
                            ? dispatch({
                                type: "UPDATE_STAND",
                                standVariantID: standVariantID,
                              })
                            : dispatch({
                                type: "UPDATE_STAND",
                                standVariantID: null,
                              })
                          updateStandCheck(e.target.value)
                        }}
                        value={addStand}
                      />
                      <span className="ml-2 text-base text-gray-800">
                        Add {stand.title}
                      </span>
                    </label>
                    <FaInfoCircle
                      className="inline text-gray-500 ml-2"
                      onClick={() => setOpenStandInfo(true)}
                    ></FaInfoCircle>
                  </div>
                  <div className="text-base font-bold text-green-money">
                    + ${standPrice}
                  </div>
                </div>
              ) : (
                <div className="flex flex-row justify-between items-center my-3"></div>
              )}

              {standInfo && standExamples["photos"].length > 0 && (
                <MoreInfoModal
                  title={stand.title}
                  open={standInfo}
                  setOpen={setOpenStandInfo}
                >
                  <Carousel images={standExamples["photos"]} />
                </MoreInfoModal>
              )}

              {/* Checkout */}
              {plaque.availableForSale ? (
                <AddToCartButton
                  loading={posting}
                  conditions={
                    artist !== "" && song !== "" && croppedPhotoURI
                      ? false
                      : true
                  }
                  addCart={() => {
                    dispatch({
                      type: "GLASS_PLAQUE_ADD_TO_CART",
                      plaqueState,
                      plaqueVariantID,
                    })
                  }}
                />
              ) : (
                <SoldOutButton />
              )}

              {/*  Product Information */}
              <div>
                <ProductDisclaimer title={"Length x Width"}>
                  The glass song plaque itself is 11 inches in length & 8.5
                  inches wide. The custom photo is 5 x 5 inches unless requested
                  otherwise. Please{" "}
                  <a
                    href="mailto:annmadeorders@gmail.com?subject=Custom Photo Size Request"
                    className="border-b border-gray-500 hover:border-annmade-1.0-blue hover:text-annmade-1.0-blue"
                  >
                    contact us
                  </a>{" "}
                  if you would like to inquiry about different photo sizing.
                  Tall arcylic stand holds plaque up to 7.5 inches wide and
                  stands 6 inches tall.
                </ProductDisclaimer>

                <ProductDisclaimer title={"Materials"}>
                  Plaque is made of clear durable glass and is not frosted.
                  Vinyl is a permanent material that should not be removed.
                  Water-resistant and UV-resistant, the vinyl lasts up to three
                  years, even outdoors.
                </ProductDisclaimer>

                <ProductDisclaimer title={"Disclaimer"}>
                  Pictures shown are for illustration purposes only; final
                  product may vary. Due to the custom nature of the product, no
                  returns or exchanges.
                </ProductDisclaimer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DesignSongPlaque

export const query = graphql`
  {
    plaque: shopifyProduct(
      shopifyId: { eq: "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzU2ODkyOTY0MjEwMTU=" }
    ) {
      availableForSale
    }
    stand: shopifyProduct(
      shopifyId: { eq: "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzU2OTA0MDc5NDQzNDM=" }
    ) {
      availableForSale
      title
      variants {
        price
      }
    }
    standExamples: contentfulAdditionalInfo(
      contentful_id: { eq: "6OIeGAPYiUNiGYGFltva1A" }
    ) {
      title
      photos {
        id
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`
